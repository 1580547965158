<template>
    <div class="gg">
        <div class="gg-score" :style="scoreStyle">{{score}}
        </div>
        <el-progress :stroke-width="10" :color="color" class="print-progress" style="display:inline-block;width: 100%;margin-left:20px;" :percentage="percentage(rank)" :format="format"></el-progress>
    </div>
</template>
<script>
export default {
    data(){
        return {
            scoreStyle: {
            },
        };
    },
    props: ["rank","score","color"],
    methods:{
        format(percentage) {
            return percentage / 10 || 0;
        },
        percentage(num) {
            if(num == null){
                num = 0;
            } else {
                num = num * 10;
            }
            return num;
        }
    },
    watch: {
        rank(val) {
            if(!!val) {
               this.scoreStyle = {
                    marginLeft: (this.format(this.rank) * 100) + "%",
                    transform: "translate(-50%, 0)",
                    color: this.color
                }
            }
        }
    }
}
</script>
<style>
.gg {
    display: inline-block;
    width: 80%;
}
.gg-score {
    display: inline-block; 
}
</style>