<template>
    <div v-loading.fullscreen.lock="loadingMap"
    element-loading-background="rgba(0, 0, 0, 0.7)">
        <div style="position: fixed;
            top: 10px;
            left: 10px;
            z-index: 10;">
            <el-button @click="printOut('商圈报告')" size="small" type="primary">导出报告</el-button><br/>
            <el-button v-print="printObj"  size="small" type="primary" style="margin-top:10px;">打印报告</el-button>
        </div>
        <div style="width: 100%;-webkit-tap-highlight-color:rgba(0,0,0,0);position: absolute;overflow-x: hidden;">
            <div class="report-container" style="padding-left:10%;padding-right:10%;" ref="imageWrapper" id="imageWrapper">
                <el-container class="report-home">
                    <el-image :src="bgUrl" :style="{height: height}" fit="fill" class="print-img-ignore"></el-image>
                    <div class="report-header">
                        <p>商圈分析报告</p>
                        <!--<p>餐饮|周边500米</p>-->
                    </div>
                    <div class="report-footer">
                        <span style="margin-right: 20px">{{companyName}}</span>
                        <span>{{date}}</span>
                    </div>
                </el-container>
                <div class="report-part print-noborder">
                    <el-row>
                        <div class="print-map" style="width:100%;height:500px"> 
                            <div ref="mmap" id="mmap" style="width:100%;height:500px"></div>
                        </div>
                    </el-row> 
                </div>
                <!-- 综合分析 -->
                <div class="report-part synthesisAnalysis">
                    <el-row style="width: 100%;">
                        <el-col :span="12">
                            <div class="report-title">
                                / 综合分析 /
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="width: 98%; margin: 20px;">
                        <el-row style="width: 100%; margin: 20px;">
                            <el-col :span="12">
                                <el-card class="print-card">
                                    <div class="report-ta-title">综合指数</div>
                                    <div class="synthesisAnalysis-content">
                                        <div style="margin:31px 10px;">
                                            <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">人口指数</div>
                                            <Gathering2 :color="'#2d8cff'" :rank="reportData.indexCustomerRank" :score="`${reportData.indexCustomerScore}`"></Gathering2>
                                            <!-- <el-tooltip class="item" effect="dark" :content="reportData.indexCustomerScore" placement="top-start">
                                                <el-progress :stroke-width="10" :color="'#2d8cff'" class="print-progress" style="display:inline-block;width: 80%;margin-left:20px;" :percentage="percentage(reportData.indexCustomerRank)" :format="format"></el-progress>
                                            </el-tooltip> -->
                                        </div>
                                        <div style="margin:30px 10px;">
                                            <div style="font-size: 12px;display: inline-block;width:60px;text-align:right">消费指数</div>
                                            <Gathering2 :color="'#8a71ff'" :rank="reportData.indexConsumeRank" :score="`${reportData.indexConsumeScore}`"></Gathering2>
                                            <!-- <el-progress :stroke-width="10" :color="'#8a71ff'" style="display:inline-block;width: 80%;margin-left:20px;" :percentage="percentage(reportData.indexConsumeRank)" :format="format"></el-progress> -->
                                        </div>
                                        <div style="margin:30px 10px;">
                                            <div style="font-size: 12px;display: inline-block;width:60px;text-align:right">交通指数</div>
                                            <Gathering2 :color="'#60e7c7'" :rank="reportData.indexTransRank" :score="`${reportData.indexTransScore}`"></Gathering2>
                                            <!-- <el-progress :stroke-width="10" :color="'#60e7c7'" style="display:inline-block;width: 80%;margin-left:20px;" :percentage="percentage(reportData.indexTransRank)"  :format="format"></el-progress> -->
                                        </div>
                                        <div style="margin:30px 10px;">
                                            <div style="font-size: 12px;display: inline-block;width:60px;text-align:right">商圈活跃度</div>
                                            <Gathering2 :color="'#ffd36f'" :rank="reportData.indexTradeAreaRank" :score="`${reportData.indexTradeAreaScore}`"></Gathering2>
                                            
                                            <!-- <el-progress :stroke-width="10" :color="'#ffd36f'" style="display:inline-block;width: 80%;margin-left:20px;" :percentage="percentage(reportData.indexTradeAreaRank)"  :format="format"></el-progress> -->
                                        </div>
                                        <div style="margin:31px 10px;">
                                            <div style="font-size: 12px;display: inline-block;width:60px;text-align:right">竞争指数</div>
                                            <Gathering2 :color="'#fe5e50'" :rank="reportData.indexBrandRank" :score="`${reportData.indexBrandScore}`"></Gathering2>
                                            <!-- <el-progress :stroke-width="10" :color="'#fe5e50'" style="display:inline-block;width: 80%;margin-left:20px;" :percentage="percentage(reportData.indexBrandRank)"  :format="format"></el-progress> -->
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <el-card class="print-card">
                                    <div class="report-ta-title">综合分析</div>
                                    <div ref="radar" style="width:100%;height:368px" class="synthesisAnalysis-content"></div>
                                </el-card>
                            </el-col>
                        </el-row>
                    </el-row>
                </div>
                <!-- 区域信息 -->
                <div class="report-part">
                    <el-row style="width: 100%;">
                        <el-col :span="12">
                            <div class="report-title">
                                / 区域信息 /
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="width: 100%; margin: 20px;">
                        <!-- 住宅 -->
                        <el-col :span="7">
                            <el-card class="print-card">
                                <div class="report-ta-title">住宅</div>
                                <el-row class="areaInfo">
                                    <el-col :span="12" style="text-align:left;">
                                        <div class="distric-card c1" style="padding:13px 0;">
                                            <div class="distric-cardnum">{{residentialCnt | formatData(0)}}</div>
                                            <div class="distric-carddes">住宅区个数</div>
                                        </div>
                                    </el-col>
                                    <el-col :span="12"  style="text-align:right;">
                                        <div class="distric-card c1" style="padding:13px 0;">
                                            <div class="distric-cardnum">{{residentialHH | formatData(0)}}</div>
                                            <div class="distric-carddes">住宅区户数</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row class="areaInfo">
                                    <el-col :span="24">
                                        <div class="distric-card2 c1" style="height: 100px;">
                                            <div style="border-bottom: 1px solid #eee;height:50px;text-align:center;">
                                                <div class="distric-cardnum">{{residentialAvgPrice | formatData(0)}}</div>
                                                <div class="distric-carddes">平均房价</div>
                                            </div>
                                            <div style="border-right: 1px solid #eee;display:inline-block;height:50px;text-align:center;width: 49%;">
                                                <div class="distric-cardnum">{{residentialMaxPrice | formatData(0)}}</div>
                                                <div class="distric-carddes">最高房价</div>
                                            </div>
                                            <div style="display:inline-block;text-align:center;width: 49%;">
                                                <div class="distric-cardnum">{{residentialMinPrice | formatData(0)}}</div>
                                                <div class="distric-carddes">最低房价</div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </el-col>
                        <!-- 办公 -->
                        <el-col :span="7" :offset="1">
                            <el-card class="print-card">
                                <div class="report-ta-title">办公</div>
                                <el-row class="areaInfo">
                                    <el-col :span="12" style="text-align:left;">
                                        <div class="distric-card c2" style="padding:13px 0;">
                                            <div class="distric-cardnum">{{officeCnt | formatData(0)}}</div>
                                            <div class="distric-carddes">办公楼个数</div>
                                        </div>
                                    </el-col>
                                    <el-col :span="12"  style="text-align:right;">
                                        <div class="distric-card c2" style="padding:13px 0;">
                                            <div class="distric-cardnum">{{officeArea | formatData(0)}}</div>
                                            <div class="distric-carddes">办公楼建筑面积</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row class="areaInfo">
                                    <el-col :span="24" style="text-align:center;">
                                        <div class="distric-card2 c2" style="height: 100px;">
                                            <div style="border-bottom: 1px solid #eee;height:50px;text-align:center;">
                                                <div class="distric-cardnum">{{officeRentAvgPrice | formatData(0)}}</div>
                                                <div class="distric-carddes">平均租金</div>
                                            </div>
                                            <div style="border-right: 1px solid #eee;display:inline-block;height:50px;text-align:center;width: 49%;">
                                                <div class="distric-cardnum">{{officeRentMaxPrice | formatData(0)}}</div>
                                                <div class="distric-carddes">最高租金</div>
                                            </div>
                                            <div style="display:inline-block;text-align:center;width: 49%;">
                                                <div class="distric-cardnum">{{officeRentMinPrice | formatData(0)}}</div>
                                                <div class="distric-carddes">最低租金</div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </el-col>
                        <!-- 商场 -->
                        <el-col :span="7" :offset="1">
                            <el-card class="print-card">
                                <div class="report-ta-title">商场</div>
                                <el-row class="areaInfo">
                                    <el-col :span="12" style="text-align:left;">
                                        <div class="distric-card c3" style="padding:13px 0;">
                                            <div class="distric-cardnum">{{mallCnt | formatData(0)}}</div>
                                            <div class="distric-carddes">购物中心个数</div>
                                        </div>
                                    </el-col>
                                    <el-col :span="12"  style="text-align:right;">
                                        <div class="distric-card c3" style="padding:13px 0;">
                                            <div class="distric-cardnum">{{mallArea | formatData(0)}}</div>
                                            <div class="distric-carddes">购物中心建筑面积(万平米)</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row class="areaInfo">
                                    <el-col :span="24" style="text-align:center;">
                                        <div class="distric-card2 c3" style="height: 100px;">
                                            <div style="border-bottom: 1px solid #eee;height:50px;text-align:center;">
                                                <div class="distric-cardnum">{{mallRentAvgPrice | formatData(0)}}</div>
                                                <div class="distric-carddes">平均租金</div>
                                            </div>
                                            <div style="border-right: 1px solid #eee;display:inline-block;height:50px;text-align:center;width: 49%;">
                                                <div class="distric-cardnum">{{mallRentMaxPrice | formatData(0)}}</div>
                                                <div class="distric-carddes">最高租金</div>
                                            </div>
                                            <div style="display:inline-block;text-align:center;width: 49%;">
                                                <div class="distric-cardnum">{{mallRentMinPrice | formatData(0)}}</div>
                                                <div class="distric-carddes">最低租金</div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </el-col>
                    </el-row>
                    <!-- 住宅 -->
                    <el-row style="width: 96%; margin: 20px;" class="print-part" v-if="houseList.length > 0">
                        <el-card>
                            <div class="report-ta-title">
                                <span>住宅(Top50)</span>  
                                <span class="downloadExcel">
                                    <el-button style="float:right;" size="mini" type="primary" @click="exportExcel(1, houseList,'住宅(Top50)')">导出</el-button>
                                </span>
                            </div>
                            <el-table
                                :data="houseList"
                                style="width: 100%"
                                :row-class-name="houseRowClassName"
                                :row-style="{height:'20px'}"
                                :cell-style="{padding:'2px'}"
                                >
                                <el-table-column
                                    type="index"
                                    label="序号"
                                    min-width="10%"

                                    show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    label="名称"
                                    min-width="20%"
                                    show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column
                                    prop="addr"
                                    label="地址"
                                    min-width="50%"
                                    show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column
                                    prop="distince"
                                    label="距商圈中心点(米)"
                                    min-width="25%">
                                </el-table-column>
                                <el-table-column
                                    prop="price"
                                    label="房价"
                                    min-width="15%">
                                </el-table-column>
                                <el-table-column
                                    prop="re_fee"
                                    label="物业费"
                                    min-width="15%">
                                </el-table-column>
                                <el-table-column
                                    prop="tot_area"
                                    label="建筑面积"
                                    min-width="15%">
                                </el-table-column>
                                <el-table-column
                                    prop="tot_hh"
                                    label="规划户数"
                                    min-width="15%">
                                </el-table-column>
                                <el-table-column
                                    prop="plot_rate"
                                    label="容积率"
                                    min-width="15%">
                                </el-table-column>
                                <el-table-column
                                    prop="green_rate"
                                    label="绿化率"
                                    min-width="15%">
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-row>
                    <!-- 办公 -->
                    <el-row  style="width: 96%; margin: 20px;" class="print-part" v-if="officeList.length > 0">
                        <el-card>
                            <div class="report-ta-title">办公(Top50)
                                <span class="downloadExcel">
                                    <el-button style="float:right;" size="mini" type="primary" @click="exportExcel(2,officeList,'办公(Top50)')">导出</el-button>
                                </span>
                            </div>
                            <el-table
                                :data="officeList"
                                style="width: 100%"
                                :row-class-name="officeRowClassName"
                                :row-style="{height:'20px'}"
                                :cell-style="{padding:'2px'}"
                                >
                                <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    label="名称"
                                    width="120"
                                    show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column
                                    prop="addr"
                                    label="地址"
                                    show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column
                                    prop="distince"
                                    label="距商圈中心点(米)"
                                    width="120">
                                </el-table-column>
                                <el-table-column
                                    prop="level"
                                    label="级别"
                                    width="80">
                                </el-table-column>
                                <el-table-column
                                    prop="tot_area"
                                    label="总建筑面积"
                                    width="80">
                                </el-table-column>
                                <el-table-column
                                    prop="floor_num"
                                    label="总楼层"
                                    width="80">
                                </el-table-column>
                                <el-table-column
                                    prop="price"
                                    label="租金(元/月/平米)"
                                    width="120">
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-row>
                    <!-- 商场 -->
                    <el-row  style="width: 96%; margin: 20px;" class="print-part" v-if="mallList.length > 0">
                        <el-card>
                            <div class="report-ta-title">商场(Top50)
                                <span class="downloadExcel">
                                    <el-button style="float:right;" size="mini" type="primary" @click="exportExcel(3,mallList,'商场(Top50)')">导出</el-button>
                                </span>
                            </div>
                            <el-table
                                :data="mallList"
                                style="width: 100%"
                                :row-class-name="mallRowClassName"
                                :row-style="{height:'20px'}"
                                :cell-style="{padding:'2px'}"
                                >
                                <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    label="名称"
                                    width="120"
                                    show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column
                                    prop="addr"
                                    label="地址"
                                    show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column
                                    prop="distince"
                                    label="距商圈中心点(米)"
                                    width="120">
                                </el-table-column>
                                <el-table-column
                                    prop="price"
                                    label="人均消费"
                                    width="80">
                                </el-table-column>
                                <el-table-column
                                    prop="open_date"
                                    label="开业时间"
                                    width="80">
                                </el-table-column>
                                <el-table-column
                                    prop="status"
                                    label="开业状态"
                                    width="80">
                                </el-table-column>
                                <el-table-column
                                    prop="tot_area"
                                    label="点址面积(万平米)"
                                    width="120">
                                </el-table-column>
                                <el-table-column
                                    prop="overall"
                                    label="总体评分"
                                    width="80">
                                </el-table-column>
                                <el-table-column
                                    prop="environment"
                                    label="环境评分"
                                    width="80">
                                </el-table-column>
                                <el-table-column
                                    prop="facility"
                                    label="设施评分"
                                    width="80">
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-row>
                </div>
                <!-- 人口分析 -->
                <div class="report-part">
                    <el-row style="width: 100%;">
                        <el-col :span="12">
                            <div class="report-title">
                                / 人口分析 /
                            </div>
                        </el-col>
                        <el-col :span="4" :offset="8">
                            <CircleProgressBar :currDay="reportData.indexCustomerRank" :progress="60" durDay="人口指数" class="print-float-right"></CircleProgressBar>
                        </el-col>
                    </el-row>
                    <el-row style="width: 96%; margin: 20px;">
                        <el-card>
                            <div>
                                <el-col :span="8">
                                    <el-col :span="4" :offset="2">
                                        <div class="pop-icon">
                                            <i class="el-icon-user"></i>
                                        </div>
                                    </el-col>
                                    <el-col :span="14" :offset="2" class="pop-content">
                                        <div>
                                            {{total_Pop.total | formatData(0)}}
                                        </div>
                                        <div>
                                            人口规模
                                        </div>
                                    </el-col>
                                </el-col>
                                <el-col :span="8">
                                    <el-col :span="4" :offset="2">
                                        <div class="pop-icon">
                                            <i class="el-icon-office-building"></i>
                                        </div>
                                    </el-col>
                                    <el-col :span="14" :offset="2" class="pop-content">
                                        <div>
                                            {{work_Pop.total | formatData(0)}}
                                        </div>
                                        <div>
                                            工作人口
                                        </div>
                                    </el-col>
                                </el-col>
                                <el-col :span="8">
                                    <el-col :span="4" :offset="2">
                                        <div class="pop-icon">
                                            <i class="el-icon-house"></i>
                                        </div>
                                    </el-col>
                                    <el-col :span="14" :offset="2" class="pop-content">
                                        <div>
                                            {{home_Pop.total | formatData(0)}}
                                        </div>
                                        <div>
                                            居住人口
                                        </div>
                                    </el-col>
                                </el-col>
                            </div>
                        </el-card>
                    </el-row>
                    <!-- 人口规模 -->
                    <div style="margin:10px;font-size:16px;font-weight: 600;">人口规模</div>
                    <el-row style="width: 96%; margin: 20px;" class="print-part">
                        <el-col :span="12">
                            <el-card  style="height: 400px;" class="print-card2">
                                <el-col :span="10">
                                    <div class="report-ta-title">性别画像</div>
                                    <div ref="totalSex" style="width: 100%;height: 340px;" class="print2-content"></div>
                                </el-col>
                                <el-col :span="14">
                                    <div class="report-ta-title2">年龄画像</div>
                                    <div style="margin:46px 10px;" class="print2-content">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">18-24岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(total_Pop.level1).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#2d8cff'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(total_Pop,total_Pop.level1)"></el-progress>
                                        </el-tooltip>
                                        
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">25-34岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(total_Pop.level2).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#8a71ff'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(total_Pop,total_Pop.level2)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">35-44岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(total_Pop.level3).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#60e7c7'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(total_Pop,total_Pop.level3)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">45+岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(total_Pop.level4).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#ffd36f'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(total_Pop,total_Pop.level4)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">其他</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(total_Pop.level5).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#fe5e50'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(total_Pop,total_Pop.level5)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                </el-col>
                            </el-card>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-card  style="height: 400px;" class="print-card2">
                                <el-col :span="14">
                                    <div class="report-ta-title">婚姻画像</div>
                                    <div ref="totalMarriage" style="width: 100%;height: 340px;" class="print2-content"></div>
                                </el-col>
                                <el-col :span="10">
                                    <div class="report-ta-title2">收入画像</div>
                                    <div ref="totalIncome" style="width: 100%;height: 340px;" class="print2-content"></div>
                                </el-col>
                            </el-card>
                        </el-col>
                    </el-row>
                    <!-- 办公 -->
                    <div style="margin:10px;font-size:16px;font-weight: 600;">工作人口</div>
                    <el-row style="width: 96%; margin: 20px;">
                        <el-col :span="12">
                            <el-card  style="height: 400px;" class="print-card3">
                                <el-col :span="10">
                                    <div class="report-ta-title">性别画像</div>
                                    <div ref="workSex" style="width: 100%;height: 340px;"></div>
                                </el-col>
                                <el-col :span="14">
                                    <div class="report-ta-title2">年龄画像</div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">18-24岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(work_Pop.level1).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#2d8cff'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(work_Pop,work_Pop.level1)"></el-progress>
                                        </el-tooltip>
                                        
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">25-34岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(work_Pop.level2).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#8a71ff'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(work_Pop,work_Pop.level2)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">35-44岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(work_Pop.level3).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#60e7c7'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(work_Pop,work_Pop.level3)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">45+岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(work_Pop.level4).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#ffd36f'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(work_Pop,work_Pop.level4)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">其他</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(work_Pop.level5).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#fe5e50'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(work_Pop,work_Pop.level5)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                </el-col>
                            </el-card>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-card  style="height: 400px;" class="print-card3">
                                <el-col :span="14">
                                    <div class="report-ta-title">婚姻画像</div>
                                    <div ref="workMarriage" style="width: 100%;height: 340px;"></div>
                                </el-col>
                                <el-col :span="10">
                                    <div class="report-ta-title2">收入画像</div>
                                    <div ref="workIncome" style="width: 100%;height: 340px;"></div>
                                </el-col>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="width: 96%; margin: 20px;" class="print-part">
                        <el-card  style="height: 400px;" class="print-card3">
                            <el-col :span="8">
                                <div class="report-ta-title">生育状况</div>
                                <div ref="workChildren" style="width: 100%;height: 340px;"></div>
                            </el-col>
                            <el-col :span="8">
                                <div class="report-ta-title2">车辆状况</div>
                                <div ref="workCar" style="width: 100%;height: 340px;"></div>
                            </el-col>
                            <el-col :span="8">
                                <div class="report-ta-title">兴趣爱好</div>
                                <div ref="workHabit" style="width: 100%;height: 340px;"></div>
                            </el-col>
                        </el-card>
                    </el-row>
                    <!-- 居民 -->
                    <div style="margin:10px;font-size:16px;font-weight: 600;">居住人口</div>
                    <el-row style="width: 96%; margin: 20px;">
                        <el-col :span="12">
                            <el-card  style="height: 400px;" class="print-card3">
                                <el-col :span="10">
                                    <div class="report-ta-title">性别画像</div>
                                    <div ref="homeSex" style="width: 100%;height: 340px;"></div>
                                </el-col>
                                <el-col :span="14">
                                    <div class="report-ta-title2">年龄画像</div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">18-24岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(home_Pop.level1).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#2d8cff'" style="display:inline-block;width: 72%;margin-left:20px;font-size:13px;" :percentage="formatAge(home_Pop,home_Pop.level1)"></el-progress>
                                        </el-tooltip>
                                        
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">25-34岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(home_Pop.level2).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#8a71ff'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(home_Pop,home_Pop.level2)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">35-44岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(home_Pop.level3).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#60e7c7'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(home_Pop,home_Pop.level3)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">45+岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(home_Pop.level4).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#ffd36f'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(home_Pop,home_Pop.level4)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">其他</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(home_Pop.level5).toFixed()" placement="top-start">
                                            <el-progress class="reProgress" :stroke-width="10" :color="'#fe5e50'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(home_Pop,home_Pop.level5)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                </el-col>
                            </el-card>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-card  style="height: 400px;" class="print-card3">
                                <el-col :span="14">
                                    <div class="report-ta-title">婚姻画像</div>
                                    <div ref="homeMarriage" style="width: 100%;height: 340px;"></div>
                                </el-col>
                                <el-col :span="10">
                                    <div class="report-ta-title2">收入画像</div>
                                    <div ref="homeIncome" style="width: 100%;height: 340px;"></div>
                                </el-col>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="width: 96%; margin: 20px;" class="print-part">
                        <el-card  style="height: 400px;" class="print-card3">
                            <el-col :span="8">
                                <div class="report-ta-title">生育状况</div>
                                <div ref="homeChildren" style="width: 100%;height: 340px;"></div>
                            </el-col>
                            <el-col :span="8">
                                <div class="report-ta-title2">车辆状况</div>
                                <div ref="homeCar" style="width: 100%;height: 340px;"></div>
                            </el-col>
                            <el-col :span="8">
                                <div class="report-ta-title">兴趣爱好</div>
                                <div ref="homeHabit" style="width: 100%;height: 340px;"></div>
                            </el-col>
                        </el-card>
                    </el-row>
                </div>
                <!-- 客流分析 -->
                <div class="report-part" v-show="false">
                    <el-row style="width: 100%;">
                        <el-col :span="12">
                            <div class="report-title">
                                / 客流分析 /
                            </div>
                        </el-col>
                        <el-col :span="4" :offset="8">
                            <CircleProgressBar :currDay="reportData.indexConsumeRank" :progress="30" durDay="消费等级" class="print-float-right"></CircleProgressBar>
                        </el-col>
                    </el-row>
                    <el-row style="width: 96%; margin: 20px;">
                        <el-col :span="12" class="weekday">
                            <div></div>
                            <div>
                                <el-row>{{weekday_Pop.total }}</el-row>
                                <el-row>工作日客流量</el-row>
                            </div>
                        </el-col>
                        <el-col :span="11" class="weekend" :offset="1">
                            <div></div>
                            <div>
                                <el-row>{{weekend_Pop.total}}</el-row>
                                <el-row>节假日客流量</el-row>
                            </div>
                        </el-col>
                    </el-row>
                    <!-- 工作日客流画像 -->
                    <div style="margin:10px;font-size:16px;font-weight: 600;">工作日客流画像</div>
                    <el-row style="width: 96%; margin: 20px;">
                        <el-col :span="12">
                            <el-card style="height: 400px;">
                                <el-col :span="10">
                                    <div class="report-ta-title">性别画像</div>
                                    <div ref="weekdaySex" style="width: 100%;height: 340px;"></div>
                                </el-col>
                                <el-col :span="14">
                                    <div class="report-ta-title2">年龄画像</div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">18-24岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekday_Pop.level1).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#2d8cff'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(weekday_Pop,weekday_Pop.level1)"></el-progress>
                                        </el-tooltip>
                                        
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">25-34岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekday_Pop.level2).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#8a71ff'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(weekday_Pop,weekday_Pop.level2)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">35-44岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekday_Pop.level3).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#60e7c7'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(weekday_Pop,weekday_Pop.level3)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">45+岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekday_Pop.level4).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#ffd36f'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(weekday_Pop,weekday_Pop.level4)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">其他</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekday_Pop.level5).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#fe5e50'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(weekday_Pop,weekday_Pop.level5)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                </el-col>
                            </el-card>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-card style="height: 400px;">
                                <el-col :span="14">
                                    <div class="report-ta-title">婚姻画像</div>
                                    <div ref="weekdayMarriage" style="width: 100%;height: 340px;"></div>
                                </el-col>
                                <el-col :span="10">
                                    <div class="report-ta-title2">收入画像</div>
                                    <div ref="weekdayIncome" style="width: 100%;height: 340px;"></div>
                                </el-col>
                            </el-card>
                        </el-col>
                    </el-row>
                    <!-- 节假日客流画像 -->
                    <div style="margin:10px;font-size:16px;font-weight: 600;">节假日客流画像</div>
                    <el-row style="width: 96%; margin: 20px;">
                        <el-col :span="12">
                            <el-card style="height: 400px;">
                                <el-col :span="10">
                                    <div class="report-ta-title">性别画像</div>
                                    <div ref="weekendSex" style="width: 100%;height: 340px;"></div>
                                </el-col>
                                <el-col :span="14">
                                    <div class="report-ta-title2">年龄画像</div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">18-24岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekend_Pop.level1).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#2d8cff'" style="display:inline-block;width: 72%;margin-left:20px;font-size:14px;" :percentage="formatAge(weekend_Pop,weekend_Pop.level1)"></el-progress>
                                        </el-tooltip>
                                        
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">25-34岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekend_Pop.level2).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#8a71ff'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(weekend_Pop,weekend_Pop.level2)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">35-44岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekend_Pop.level3).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#60e7c7'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(weekend_Pop,weekend_Pop.level3)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">45+岁</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekend_Pop.level4).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#ffd36f'" style="display:inline-block;width: 72%;margin-left:20px;" :percentage="formatAge(weekend_Pop,weekend_Pop.level4)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin:46px 10px;">
                                        <div style="font-size: 12px; display: inline-block;width:60px;text-align:right">其他</div>
                                        <el-tooltip class="item" effect="dark" :content="Number(weekend_Pop.level5).toFixed()" placement="top-start">
                                            <el-progress :stroke-width="10" :color="'#fe5e50'" style="display:inline-block;width: 72%;margin-left:20px;font-size:14px;" :percentage="formatAge(weekend_Pop,weekend_Pop.level5)"></el-progress>
                                        </el-tooltip>
                                    </div>
                                </el-col>
                            </el-card>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-card style="height: 400px;">
                                <el-col :span="14">
                                    <div class="report-ta-title">婚姻画像</div>
                                    <div ref="weekendMarriage" style="width: 100%;height: 340px;"></div>
                                </el-col>
                                <el-col :span="10">
                                    <div class="report-ta-title2">收入画像</div>
                                    <div ref="weekendIncome" style="width: 100%;height: 340px;"></div>
                                </el-col>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
                <!-- 交通分析 -->
                <div class="report-part synthesisAnalysis">
                    <el-row style="width: 100%;">
                        <el-col :span="12">
                            <div class="report-title">
                                / 交通分析 /
                            </div>
                        </el-col>
                        <el-col :span="4" :offset="8">
                            <CircleProgressBar :currDay="reportData.indexTransRank" :progress="reportData.indexTransRank*10" durDay="交通指数" class="print-float-right"></CircleProgressBar>
                            <!-- <CircleProgressBar :desc="'交通指数'" :currDay="reportData.indexTransRank" :progress="`${reportData.indexTransRank*100/10}`"></CircleProgressBar> -->
                        </el-col>
                    </el-row>
                    <el-row style="width: 100%; margin: 20px;">
                        <el-col :span="11">
                            <el-card class="print-card">
                                <div class="report-ta-title">交通分析</div>
                                <div ref="tfNum" style="width:100%;height:376px" class="synthesisAnalysis-content"></div>
                            </el-card>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-card class="print-card">
                                <div class="report-ta-title">分布图</div>
                                <div ref="map" id="map" style="width:100%;height:376px" class="synthesisAnalysis-content"></div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
                <!-- 商圈分析 -->
                <div class="report-part synthesisAnalysis">
                    <el-row style="width: 100%;">
                        <el-col :span="12">
                            <div class="report-title">
                                / 商圈分析 /
                            </div>
                        </el-col>
                        <el-col :span="4" :offset="8">
                            <CircleProgressBar :currDay="reportData.indexTradeAreaRank" :progress="reportData.indexTradeAreaRank*10" durDay="商圈活跃度" class="print-float-right"></CircleProgressBar>
                            <!-- <CircleProgressBar :currDay="reportData.indexTradeAreaRank" :desc="'商圈活跃度'"></CircleProgressBar> -->
                        </el-col>
                    </el-row>
                    <el-row style="width: 100%; margin: 20px;">
                        <el-col :span="7">
                            <el-card class="print-card">
                                <div class="report-ta-title">聚客点</div>
                                <Gathering :ObjList="shoplist" style="width:100%;height:400px;margin-top:20px;" class="synthesisAnalysis-content printGathering"></Gathering>
                            </el-card>
                        </el-col>
                        <el-col :span="7" :offset="1">
                            <el-card class="print-card">
                                <div class="report-ta-title">类型数量</div>
                                <div style="width:100%;height:420px">
                                    <div ref="taNum" style="width:100%;height:420px" class="synthesisAnalysis-content"></div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="7" :offset="1">
                            <el-card class="print-card">
                                <div class="report-ta-title">热力图</div>
                                <div ref="heatmap" id="heatmap" style="width:100%;height:420px" class="synthesisAnalysis-content"></div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
                <!-- 竞争分析 --> 
                <div class="report-part">
                    <el-row style="width: 100%;">
                        <el-col :span="12">
                            <div class="report-title">
                                / 竞争分析 /
                            </div>
                        </el-col>
                        <el-col :span="4" :offset="8">
                            <CircleProgressBar :currDay="reportData.indexBrandRank" :progress="80" durDay="竞争指数" class="print-float-right"></CircleProgressBar>
                        </el-col>
                    </el-row>
                    <el-row  style="width: 96%; margin: 20px;" v-if="true">
                        <el-card>
                            <div class="report-ta-title">
                                <span>品牌统计</span>  
                                <!-- <el-button style="float:right;" size="mini" type="primary" @click="exportExcel(4, brandList,'关注品牌列表')">导出</el-button> -->
                            </div>
                            <el-row style="margin: 20px;">
                                <el-col :span="4" v-for="(item, index) in brandCountList" :key="index">
                                    <div>
                                        <el-row style="font-size: 20px;text-align: center;">
                                            {{item.brandNum}}
                                        </el-row>
                                        <el-row style="font-size: 14px;text-align: center;margin-top:5px;margin-bottom:15px;">
                                            {{item.brand}}
                                        </el-row>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-row>
                    <el-row  style="width: 96%; margin: 20px;" v-if="brandList.length > 0">
                        <el-card>
                            <div class="report-ta-title">
                                <span>关注品牌列表</span>  
                                <span class="downloadExcel">
                                    <el-button style="float:right;" size="mini" type="primary" @click="exportExcel(4, brandList,'关注品牌列表')">导出</el-button>
                                </span>
                            </div>
                            <el-table
                                :data="brandList"
                                :row-class-name="houseRowClassName"
                                :row-style="{height:'20px'}"
                                :cell-style="{padding:'2px'}"
                                class="print-table"
                                >
                                <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                                </el-table-column>
                                <el-table-column
                                    prop="brand"
                                    label="品牌"
                                    width="120">
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    label="门店名称">
                                </el-table-column>
                                <el-table-column
                                    prop="distince"
                                    label="距商圈中心点(米)"
                                    width="120">
                                </el-table-column>
                                <el-table-column
                                    prop="phone"
                                    label="电话"
                                    width="200">
                                </el-table-column>
                                <el-table-column
                                    prop="address"
                                    label="地址">
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import bgImg from "@/assets/images/report/bg.jpg";
import CircleProgressBar from "@/components/CircleProgressBar.vue";
import Gathering from "@/components/Gathering.vue";
import Gathering2 from "@/components/Gathering2.vue";
import api from "@/api/index.js";
import constants from "@/constants/Map.constants.js";
var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
import $ from 'jquery'

import html2canvas from 'html2canvas';
//import JsPDF from 'jspdf';
import printJS from 'print-js';

//import DevicePixelRatio from '@/lib/devicePixelRatio.js';
export default {
    name: "anlysisReport",
    data() {
        return {
            map:null,//地图实例
            heatmap:null,//热力图实例
            bgUrl: bgImg,
            shoplist:[],//聚客区
            residentialAvgPrice:'',//住宅区平均房价
            residentialCnt:'',//住宅区个数
            residentialHH:'',//住宅区户数
            residentialMaxPrice:'',//住宅区最高房价
            residentialMinPrice:'',//住宅区最低房价
            officeArea:'',//办公楼建筑面积
            officeCnt:'',//办公楼个数
            officeRentAvgPrice:'',//办公楼平均租金
            officeRentMaxPrice:'',//办公楼最高租金
            officeRentMinPrice:'',//办公楼最低租金
            mallArea:'',//购物中心建筑面积
            mallCnt:'',//购物中心个数
            mallRentAvgPrice:'',//购物中心平均租金
            mallRentMaxPrice:'',//购物中心最高租金
            mallRentMinPrice:'',//购物中心最低租金
            ops: {},
            reportData: {},
            curDay: "",
            height: "450px",
            date: "",
            companyName: "",
            houseList: [], // 住宅详情列表
            officeList: [], // 办公详情列表
            mallList: [], // 商场详情列表
            geoPop: {}, // 人口分析
            home_Pop: {}, //
            total_Pop: {},
            weekday_Pop: {}, 
            weekend_Pop: {}, 
            work_Pop: {},
            brandSurveyList: [],
            brandCountList: [],
            menuVisible: false,
            reportID: "",
            loadingMap: false,
            brandList: [],
            printObj: {
                id: "imageWrapper",
                popTitle: "址见-智慧选址"
            }
        };
    },
    filters: {
        //格式数据小数点位
        formatData(value, fix){
            let result = 0;
            if(value != null && value != 0 && value != undefined) {
                result = Number(value).toFixed(fix);
            }
            if(value == null || value == undefined)
                result = "-";
            return result;
        }
    },
    components: {
        CircleProgressBar, 
        Gathering,
        Gathering2
    },
    created(){
       // new DevicePixelRatio().init();
    },
    mounted() {
        this.reportID = JSON.parse(this.$route.params.info).reportID;
        this.$store.commit("startLoading");
        this.ops = JSON.parse(unescape(sessionStorage.info));
        if(JSON.stringify(this.ops) == "{}"){
            this.ops = sessionStorage.getItem("ops");
        } else {
            sessionStorage.setItem("ops", this.ops);
        }
        this.curDay = this.$Format("yyyy-MM-dd", new Date());
        this.initData();
        this.height = window.screen.height-150 + "px";
        var res = {data: JSON.parse(sessionStorage.res)};
        this.brandList = res.data.brandList;
    },
    methods: {
        percentage(num) {
            if(num == null){
                num = 0;
            } else {
                num = num * 10;
            }
            return num;
        },
        format(percentage) {
            return percentage / 10 || "0";
        },
        formatAge(model, level) {
            let res = 0;
            if(JSON.stringify(model) != "{}") {
                let total = Number(model.total);
                res = (Number(level) * 100 / total).toFixed(2);
            }
            return Number(res);
        },
        initData() {
            switch (this.ops.type) {
                case "ta":
                    this.taReport();
                    break;
                case "buffer":
                    this.bufferReport();
                    break;
                case "pp":
                case "store":
                case "walking":
                case "riding":
                case "driving":
                    this.isochronousReport();
            }
        },
        //等时圈报告
        isochronousReport() {
            var that = this;
            setTimeout(() => {
                var res = {data: JSON.parse(sessionStorage.res)};
                try{
                    that.handleData(res);
                    that.houseList = res.data.houseList;
                    that.officeList = res.data.officeList;
                    that.mallList = res.data.mallList;
                } catch(err){
                    that.$message.error("2" +err);
                };
            }, 1000);
        },
        //商圈报告
        taReport() {
            var that = this;
            setTimeout(() => {
                var res = {data: JSON.parse(sessionStorage.res)};
                try {
                    that.handleData(res);
                    that.houseList = res.data.houseList;
                    that.officeList = res.data.officeList;
                    that.mallList = res.data.mallList;
                } catch (error) {
                    that.$message.error("3" +err);
                }
            }, 1000);
        },
        //缓冲区报告
        bufferReport() {
            var that = this;
            setTimeout(() => {
                var res = {data: JSON.parse(sessionStorage.res)};
                try {
                    that.handleData(res);
                    that.houseList = res.data.houseList;
                    that.officeList = res.data.officeList;
                    that.mallList = res.data.mallList;
                } catch (error) {
                    that.$message.error("1" +error);
                    console.log(error);
                }
            }, 1000);
        },
        //处理数据
        handleData(res) {
            this.reportData = res.data;
            this.geoPop = res.data.geoPop;
            this.home_Pop = this.geoPop.home_Pop || {}; //
            this.total_Pop= this.geoPop.total_Pop || {}; //
            this.weekday_Pop= this.geoPop.weekday_Pop || {}; //
            this.weekend_Pop= this.geoPop.weekend_Pop || {}; //
            this.work_Pop= this.geoPop.work_Pop || {}; //
            this.brandCountList = res.data.brandCountList;
            this.initEcharts();
            this.initPopEcharts("total");
            this.initPopEcharts("home");
            this.initPopEcharts("work");
            this.initPopEcharts("weekday");
            this.initPopEcharts("weekend");
            this.initMMap();
            this.initMap();
            this.initHeatMap();
            
            //取前几个
           // this.shoplist=res.data.marketRank.splice(0,7);
            this.shoplist = [{number: this.reportData.poiShopSuperMarketCnt, name: '大型商场', color: "#ffc025"},
                            {number: this.reportData.poiShopMallCnt, name: '超市', color: "#be6afe"},
                            {number: this.reportData.poiShopCvsCnt, name: '便利店', color: "#31fbcb"},
                         //   {number: this.reportData.poiShopMallCnt, name: '集市'}, //
                           // {number: this.reportData.poiShopMallCnt, name: '家电数码'}, //
                           // {number: this.reportData.poiShopMallCnt, name: '家居建材'}, //
                            {number: this.reportData.poiEduSchoolCnt, name: '教育', color: "#ff7a99"},
                            {number: this.reportData.poiMedicalHospitalCnt, name: '医疗', color: "#566cff"},
                            {number: this.reportData.poiWorkGovernmentCnt, name: '政府', color: "#ffa660"},
                          //  {number: this.reportData.poiShopMallCnt, name: '娱乐'}, //
                            {number: this.reportData.tourismCnt, name: '旅游', color: "#6ee97e"},
                            //{number: this.reportData.tourismCnt, name: '丽人'}
                            ]

            this.residentialAvgPrice=res.data.residentialAvgPrice;
            this.residentialCnt=res.data.residentialCnt;
            this.residentialHH=res.data.residentialHH;
            this.residentialMaxPrice=res.data.residentialMaxPrice;
            this.residentialMinPrice=res.data.residentialMinPrice;
        
            this.officeArea=res.data.officeArea;
            this.officeCnt=res.data.officeCnt;
            this.officeRentAvgPrice=res.data.officeRentAvgPrice;
            this.officeRentMaxPrice=res.data.officeRentMaxPrice;
            this.officeRentMinPrice=res.data.officeRentMinPrice;

            this.mallArea=res.data.mallArea;
            this.mallCnt=res.data.mallCnt;
            this.mallRentAvgPrice=res.data.mallRentAvgPrice;
            this.mallRentMaxPrice=res.data.mallRentMaxPrice;
            this.mallRentMinPrice=res.data.mallRentMinPrice;
            this.companyName = res.data.companyName;
            this.date = res.data.date;
            this.$store.commit("closeLoading");
            var that = this;
            this.$nextTick(res=>{
               // that.initBrandEcharts();
            });
                    
        },
        //交通地图
        initMap() {
            var that=this;
            mapboxgl.accessToken = 'pk.eyJ1IjoiYTczNjc1NTI0NCIsImEiOiJjazU0eHdrMmcwb2NqM2ZxdnRzbnlvbnl0In0.gEXGZsmSdpJt5Zg9vMCilA';
            that.map = new mapboxgl.Map({
                container: this.$refs.map,
                style:{
                    "version": 8,
                    "name": "Mapbox Streets",
                    "sprite": "mapbox://sprites/mapbox/streets-v8",
                    "glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
                    "sources": {
                        "osm-tiles": {
                            "type": "raster",
                            "tiles": ["https://wprd03.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}"],
                            "tileSize": 256
                        }
                    },
                    "layers":[{
                        "id": "main",
                        "type": "raster",
                        "source": "osm-tiles",
                        "source-layer": "osmtiles"
                    }]
                },
                center: [this.ops.longitude, this.ops.latitude], 
                zoom: 13, // 初始缩放大小
                maxZoom: 18,
                doubleClickZoom: false,// 禁止双击缩放
                localIdeographFontFamily: "'Noto Sans', 'Noto Sans CJK SC', sans-serif", //字体
                interactive: false,
                preserveDrawingBuffer: true 
            });
            that.map.loadImage(require('../assets/images/marker/metr.png'), function (error, image) {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!that.map.hasImage('metr')) {
                    that.map.addImage('metr', image)
                }}
            )
            that.map.loadImage(require('../assets/images/marker/bus.png'), function (error, image) {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!that.map.hasImage('bus')) {
                    that.map.addImage('bus', image)
                }}
            )
            that.map.loadImage(require('../assets/images/marker/park.png'), function (error, image) {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!that.map.hasImage('park')) {
                    that.map.addImage('park', image)
                }}
            )
            var img = require('../assets/images/marker/dizhi.png');
            if(that.ops.type == "pp"){
                img = require('../assets/images/marker/jihuidian2.png');
            } else if(that.ops.type == "store"){
                img = require('../assets/images/marker/mendian2.png');
            }
            that.map.loadImage(img, function (error, image) {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!that.map.hasImage('dizhi')) {
                    that.map.addImage('dizhi', image);
                }}
            )
            that.map.on('load', function() {
                that.drawMaps();
                that.drawMapLayer(that.map);
            })

        },
        //画公交站、地铁站、停车场等坐标信息==要根据类型匹配不同标注图片
        drawMaps() {
            var that = this;
            var geojsonPoints={
                "type": "FeatureCollection",
                "features": []
            };
            var len=that.reportData.transLocation.length;
            for(var i=0;i<len;i++){
                var item=this.reportData.transLocation[i];
                item.attribution = JSON.parse(item.attribution.replace(/'/g, `"`));
                var icon = "bus";
                switch (item.attribution.cls2) {
                    case "地铁站":
                        icon = "metr";
                        break;
                    case "停车场":
                        icon = "park";
                        break;
                    case "公交车站":
                        icon = "bus";
                        break;
                    default:
                        break;
                }
                var temp={
                    "type": "Feature",
                    "properties": {
                        "id": item.id,
                        "attr": item.attribution,
                        "type": item.type,
                        "icon": icon
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": [JSON.parse(item.geometry)[0],JSON.parse(item.geometry)[1]]
                    }
                };
                geojsonPoints.features.push(temp);
            }
            if (that.map.getLayer('pointlayer')) {
                that.map.getSource('geodataPoint').setData(geojsonPoints);
            }else{
                that.map.addSource('geodataPoint', { type: 'geojson', data: geojsonPoints });
                that.map.addLayer({
                    'id': 'pointlayer',
                    'type': 'symbol',
                    'source': "geodataPoint",
                    "layout": {
                        'icon-image': ['get', 'icon'],
                        'icon-size': 1,
                        "icon-allow-overlap": true
                    }
                });
            }        
        },
        //初始化所有echarts 图表
        initEcharts() {
            var that = this;
            //商圈进度
            let taProgress = this.$refs.taNum;
            let taProgressChart = this.echarts.init(taProgress);
            var taOption = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    //orient: 'vertical',
                    icon: "circle",
                    itemWidth: 4,
                    itemHeight: 6,
                    itemGap: 15,
                    bottom: 1,
                    data: ['大型商场', '超市', '便利店', /*'集市', '家电数码', '家居建材', */'教育', '医疗', '政府',/* '娱乐',*/ '旅游']//, '丽人']
                },
                series: [
                    {
                        name: '类型',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                         
                        data: [
                            {value: this.reportData.poiShopSuperMarketCnt, name: '大型商场'},
                            {value: this.reportData.poiShopMallCnt, name: '超市'},
                            {value: this.reportData.poiShopCvsCnt, name: '便利店'},
                           // {value: this.reportData.poiShopMallCnt, name: '集市'}, //
                           // {value: this.reportData.poiShopMallCnt, name: '家电数码'}, //
                          //  {value: this.reportData.poiShopMallCnt, name: '家居建材'}, //
                            {value: this.reportData.poiEduSchoolCnt, name: '教育'},
                            {value: this.reportData.poiMedicalHospitalCnt, name: '医疗'},
                            {value: this.reportData.poiWorkGovernmentCnt, name: '政府'},
                          //  {value: this.reportData.poiShopMallCnt, name: '娱乐'}, //
                            {value: this.reportData.tourismCnt, name: '旅游'},
                           // {value: 135, name: '丽人'}
                        ],
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                            normal:{
                                color:function(params) {
                                //自定义颜色
                                    var colorList = [
                                        '#ffc025', '#be6afe', '#31fbcb', '#ff7a99', '#566cff', '#ffa660',
                                        '#6ee97e', '#50d0ff', '#4892fd', '#8369ff', '#ffb9c9', '#ff72e5'
                                    ];
                                    return colorList[params.dataIndex];
                                }
                            }
                        }
                    }
                ]
            };
            taProgressChart.setOption(taOption);

            let tfProgress = this.$refs.tfNum;
            let tfProgressChart = this.echarts.init(tfProgress);
            var tfOption = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    x: "right",
                    y: "center",
                    itemWidth: 6,
                    itemHeight: 6,
                    itemGap: 20,
                    data: ['公交', '地铁', '停车场'],
                    align:'right',
                    formatter(name){
                        var arr = [];
                        
                        switch (name) {
                            case "公交":
                                arr.push(name + ` ${that.reportData.poiTransBusCnt}`);
                                break;
                            case "地铁":
                                arr.push(name + ` ${that.reportData.poiTransMetroCnt}`);
                                break;
                            case "停车场":
                                arr.push(name + ` ${that.reportData.poiTransParkingCnt}`);
                                break;
                            default:
                                break;
                        }
                        return arr;
                    }
                },
                series: [
                    {
                        name: '交通',
                        type: 'pie',
                        radius: ['30%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            {value: this.reportData.poiTransBusCnt, name: '公交'},
                            {value: this.reportData.poiTransMetroCnt, name: '地铁'},
                            {value: this.reportData.poiTransParkingCnt, name: '停车场'}
                        ],
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                            normal:{
                                color:function(params) {
                                //自定义颜色
                                var colorList = [          
                                        '#8369ff', '#6eee7d', '#ffa45d'
                                    ];
                                    return colorList[params.dataIndex]
                                    }
                            }
                        }
                    }
                ],
                 grid:{
                   // x:'2%',y:'2%',x2:'12%',y2:'12%'
                   right: "20%",
                   top: "0%"
                }
            };
            tfProgressChart.setOption(tfOption);
            //综合分析
            let radar = this.$refs.radar;
            let radarChart = this.echarts.init(radar);
            var radarOption = {
                tooltip: {},
                radar: {
                    name: {
                        textStyle: {
                            color: 'black',
                            borderRadius: 3,
                            padding: [3, 5]
                        }
                    },
                    indicator: [
                        {name: '人口', max: 10},
                        {name: '交通', max: 10},
                        {name: '消费', max: 10},
                        {name: '竞争', max: 10},
                        {name: '商圈', max: 10}
                    ]
                },
                series: [{
                    name: '能力',
                    type: 'radar',
                    areaStyle: {normal: {
                        color: "#ffe8e8",
                        borderWidth: 0,
                    }},
                    data: [
                        {name: "综合分析", value: [that.reportData.indexCustomerRank, that.reportData.indexTransRank, that.reportData.indexConsumeRank, that.reportData.indexBrandRank, that.reportData.indexTradeAreaRank]}
                    ]
                }]
            };
            radarChart.setOption(radarOption);
            //
            
        },
        initPopEcharts(type) {
            var that = this;
            let model = {};
            switch (type) {
                case "total":
                    model = that.total_Pop;
                    break;
                case "home":
                    model = that.home_Pop;
                    break;
                case "work":
                    model = that.work_Pop;
                    break;
                case "weekday":
                    model = that.weekday_Pop;
                    break;
                case "weekend":
                    model = that.weekend_Pop;
                    break;
                default:
                    break;
            }
            if(JSON.stringify(model) == "{}") {
                return;
            }
            // total
            let totalSex = this.$refs[type + "Sex"];
            let totalSexEChart = this.echarts.init(totalSex);
            var totalSexOption = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    x: "center",
                    y: "bottom",
                    itemWidth: 6,
                    itemHeight: 6,
                    itemGap: 20,
                    data: ['男性', '女性'],
                    align:'right',
                    formatter(name){
                        var arr = [];
                        var total = Number(model.male) + Number(model.female);
                        switch (name) {
                            case "男性":
                                var percent = (Number(model.male) * 100 /total).toFixed(2) + "%";
                                arr.push(name + ` ${percent}`);
                                break;
                            case "女性":
                                var percent = (Number(model.female) * 100 /total).toFixed(2) + "%";
                                arr.push(name + ` ${percent}`);
                                break;
                            default:
                                break;
                        }
                        return arr;
                    }
                },
                series: [
                    {
                        name: '人口性别',
                        type: 'pie',
                        // radius: '25%',
                         center: ['50%', '50%'],
                        //radius: ['30%', '70%'],
                        avoidLabelOverlap: false,
                        // label: {
                        //     show: false,
                        //     position: 'center'
                        // },
                        labelLine: {
                           show: false
                        },
                        label: {
                            formatter: '{c}',
                            position: 'inner'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        
                        data: [
                            {value: Number(model.male).toFixed(), name: '男性'},
                            {value: Number(model.female).toFixed(), name: '女性'}
                        ],
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                            normal:{
                                color:function(params) {
                                //自定义颜色
                                var colorList = [          
                                        '#8369ff', '#f76bff'
                                    ];
                                    return colorList[params.dataIndex]
                                    }
                            }
                        }
                    }
                ],
                 grid:{
                   // x:'2%',y:'2%',x2:'12%',y2:'12%'
                   right: "20%",
                   top: "0%"
                }
            };
            totalSexEChart.setOption(totalSexOption);
            let totalIncome = this.$refs[type + "Income"];
            let totalIncomeEChart = this.echarts.init(totalIncome);
            var totalIncomeOption = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    x: "center",
                    y: "bottom",
                    itemWidth: 6,
                    itemHeight: 6,
                    itemGap: 20,
                    data: ['高', '中', "低"],
                    align:'right',
                    formatter(name){
                        var arr = [];
                        var total = Number(model.high) + Number(model.mid) + Number(model.low);
                        switch (name) {
                            case "高":
                                var percent = (Number(model.high) * 100 /total).toFixed(2) + "%";
                                arr.push(name + "(2万以上)" + ` ${percent}`);
                                break;
                            case "中":
                                var percent = (Number(model.mid) * 100 /total).toFixed(2) + "%";
                                arr.push(name + "(8千-2万)" +  ` ${percent}`);
                                break;
                            case "低":
                                var percent = (Number(model.low) * 100 /total).toFixed(2) + "%";
                                arr.push(name + "(8千以下)" + ` ${percent}`);
                                break;
                            default:
                                break;
                        }
                        return arr;
                    }
                },
                series: [
                    {
                        name: '人口收入',
                        type: 'pie',
                        radius: ['30%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            {value: Number(model.high).toFixed(), name: '高'},
                            {value: Number(model.mid).toFixed(), name: '中'},
                            {value: Number(model.low).toFixed(), name: '低'}
                        ],
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                            normal:{
                                color:function(params) {
                                //自定义颜色
                                var colorList = [          
                                        '#ffc025', '#fe786c', '#4ac5ff'
                                    ];
                                    return colorList[params.dataIndex]
                                    }
                            }
                        }
                    }
                ],
                grid:{
                    // x:'2%',y:'2%',x2:'12%',y2:'12%'
                    right: "20%",
                    top: "0%"
                }
            };
            totalIncomeEChart.setOption(totalIncomeOption);
            let totalMarriage = this.$refs[type + "Marriage"];
            let totalMarriageEChart = this.echarts.init(totalMarriage);
            var totalMarriageOption = {
                xAxis: {
                    type: 'category',
                    data: ['已婚', '未婚']
                },
                yAxis: {
                    show: false
                },
                series: [{
                    data: [Number(model.married).toFixed(), Number(model.unmarried).toFixed()],
                    type: 'bar',
                    barWidth : 20,//柱图宽度
                    itemStyle: {
                        normal: {
                            //每根柱子颜色设置
                            color: function(params) {
                                let colorList = [
                                    "#ff70b5",
                                    "#37e0b7"
                                ];
                                return colorList[params.dataIndex];
                            }
                        }
                    },
                    //柱状图上显示数据
                    label: {
                        show: "true",
                        position: "top",
                        color: "#FFF",
                        backgroundColor: "auto",
                        fontSize: "12"
                    },
                }]
            };
            totalMarriageEChart.setOption(totalMarriageOption);
            if(type == "home" || type == "work" ) {
                let totalChildren = this.$refs[type + "Children"];
                let totalChildrenEChart = this.echarts.init(totalChildren);
                var totalChildrenOption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    legend: {
                        icon: "circle",
                        itemWidth: 4,
                        itemHeight: 6,
                        itemGap: 15,
                        bottom: 1,
                        data: ['孕期', '0-3岁父母', "3-6岁父母", "其他"],
                        align:'right'
                    },
                    series: [
                        {
                            name: '生育状况',
                            type: 'pie',
                            radius: ['30%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: Number(model.children_pregnant).toFixed(), name: '孕期'},
                                {value: Number(model.children_baby1).toFixed(), name: '0-3岁父母'},
                                {value: Number(model.children_baby2).toFixed(), name: '3-6岁父母'},
                                {value: Number(model.children_other).toFixed(), name: '其他'} //
                            ],
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                                normal:{
                                    color:function(params) {
                                    //自定义颜色
                                    var colorList = [          
                                            '#ffc025', '#fe786c', '#4ac5ff', '#888'
                                        ];
                                        return colorList[params.dataIndex]
                                    }
                                }
                            }
                        }
                    ],
                    grid:{
                        // x:'2%',y:'2%',x2:'12%',y2:'12%'
                        right: "20%",
                        top: "0%"
                    }
                };
                totalChildrenEChart.setOption(totalChildrenOption);
                //
                let totalCar = this.$refs[type + "Car"];
                let totalCarEChart = this.echarts.init(totalCar);
                var totalCarOption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    legend: {
                        icon: "circle",
                        itemWidth: 4,
                        itemHeight: 6,
                        itemGap: 15,
                        bottom: 1,
                        data: ['有车', '无车'],
                        align:'right',
                        
                    },
                    series: [
                        {
                            name: '车辆状况',
                            type: 'pie',
                            radius: ['30%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: Number(model.car_yes).toFixed(), name: '有车'},
                                {value: Number(model.car_no).toFixed(), name: '无车'}
                            ],
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    },
                                normal:{
                                    color:function(params) {
                                    //自定义颜色
                                    var colorList = [          
                                            '#566cff', '#ffa660'
                                        ];
                                        return colorList[params.dataIndex]
                                    }
                                }
                            }
                        }
                    ],
                    grid:{
                        // x:'2%',y:'2%',x2:'12%',y2:'12%'
                        right: "20%",
                        top: "0%"
                    }
                };
                totalCarEChart.setOption(totalCarOption);
                //
                let totalHabit = this.$refs[type + "Habit"];
                let totalHabitEChart = this.echarts.init(totalHabit);
                var totalHabitOption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    legend: {
                        icon: "circle",
                        itemWidth: 4,
                        itemHeight: 6,
                        itemGap: 10,
                        bottom: 1,
                        data: ['金融理财', '购物', '教育', '旅游', '汽车', '运动健康', '其他'],
                        align:'right',
                    },
                    series: [
                        {
                            name: '兴趣爱好',
                            type: 'pie',
                            radius: ['30%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: Number(model.hobby_financial).toFixed(), name: '金融理财'},
                                {value: Number(model.hobby_shop).toFixed(), name: '购物'},
                                {value: Number(model.hobby_education).toFixed(), name: '教育'},
                                {value: Number(model.hobby_travel).toFixed(), name: '旅游'},
                                {value: Number(model.hobby_car).toFixed(), name: '汽车'},
                                {value: Number(model.hobby_sport).toFixed(), name: '运动健康'},
                                {value: Number(model.hobby_other).toFixed(), name: '其他'}, //hobby_other
                            ],
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    },
                                normal:{
                                    color:function(params) {
                                    //自定义颜色
                                    var colorList = [          
                                            '#6ee97e', '#50d0ff', '#4892fd', '#8369ff', '#ffb9c9', '#ff72e5', '#888'
                                        ];
                                        return colorList[params.dataIndex]
                                    }
                                }
                            }
                        }
                    ],
                    grid:{
                        // x:'2%',y:'2%',x2:'12%',y2:'12%'
                        right: "20%",
                        top: "0%"
                    }
                };
                totalHabitEChart.setOption(totalHabitOption);
            }
        },
        //热力地图
        initHeatMap() {
            var that=this;
            mapboxgl.accessToken = 'pk.eyJ1IjoiYTczNjc1NTI0NCIsImEiOiJjazU0eHdrMmcwb2NqM2ZxdnRzbnlvbnl0In0.gEXGZsmSdpJt5Zg9vMCilA';
            that.heatmap = new mapboxgl.Map({
                container: this.$refs.heatmap,
                style:{
                    "version": 8,
                    "name": "Mapbox Streets",
                    "sprite": "mapbox://sprites/mapbox/streets-v8",
                    "glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
                    "sources": {
                        "osm-tiles": {
                            "type": "raster",
                            "tiles": ["https://wprd03.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}"],
                            "tileSize": 256
                        }
                    },
                    "layers":[{
                        "id": "main",
                        "type": "raster",
                        "source": "osm-tiles",
                        "source-layer": "osmtiles"
                    }]
                },
                center: [this.ops.longitude, this.ops.latitude], // 初始定位坐标 [lng, lat]
                zoom: 13, // 初始缩放大小
                maxZoom: 18,
                doubleClickZoom: false,// 禁止双击缩放
                localIdeographFontFamily: "'Noto Sans', 'Noto Sans CJK SC', sans-serif", //字体
                interactive: false,
                preserveDrawingBuffer: true 
            });
            that.heatmap.on('load', function() {
                that.drawHeatMap();
            })
        },
        //初始化地图
        initMMap() {
            var that=this;
            mapboxgl.accessToken = 'pk.eyJ1IjoiYTczNjc1NTI0NCIsImEiOiJjazU0eHdrMmcwb2NqM2ZxdnRzbnlvbnl0In0.gEXGZsmSdpJt5Zg9vMCilA';
            that.mmap = new mapboxgl.Map({
                container: this.$refs.mmap,
                style:{
                    "version": 8,
                    "name": "Mapbox Streets",
                    "sprite": "mapbox://sprites/mapbox/streets-v8",
                    "glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
                    "sources": {
                        "osm-tiles": {
                            "type": "raster",
                            "tiles": ["https://wprd03.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}"],
                            "tileSize": 256
                        }
                    },
                    "layers":[{
                        "id": "main",
                        "type": "raster",
                        "source": "osm-tiles",
                        "source-layer": "osmtiles"
                    }]
                },
                center: [this.ops.longitude, this.ops.latitude], // 初始定位坐标 [lng, lat]
                zoom: 13, // 初始缩放大小
                maxZoom: 18,
                doubleClickZoom: false,// 禁止双击缩放
                localIdeographFontFamily: "'Noto Sans', 'Noto Sans CJK SC', sans-serif", //字体
                interactive: false,
                preserveDrawingBuffer: true
            });
            var img = require('../assets/images/marker/dizhi.png');
            if(that.ops.type == "pp"){
                img = require('../assets/images/marker/jihuidian2.png');
            } else if(that.ops.type == "store"){
                img = require('../assets/images/marker/mendian2.png');
            }
            that.mmap.loadImage(img, function (error, image) {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!that.mmap.hasImage('dizhi')) {
                    that.mmap.addImage('dizhi', image);
                }}
            )
            that.mmap.on('load', function() {
                that.drawMapLayer(that.mmap);
                that.drawTALayer();
            })
        },
        //删除图层
        removeLayer(layerid) {
            var that=this;
            if (that.map.getLayer(layerid)) {
                that.map.removeLayer(layerid);
            }
        },
        //删除资源
        removeSource(sourceid) {
            var that=this;
            if(that.map.getSource(sourceid)){
                that.map.removeSource(sourceid);
            }
        },
        //商圈(热力图)
        drawHeatMap() {
            var that=this;
            var ak = 'NWFlZjQ4Y2IxOTM3NDNjM2E1N2VhOGY0NmI2ODM0ZWU';
            var vizConfig = [{
                "dataUid": "1b5dbae8838911e998dfa0d3c1f46be8",
                "dataType": "private",
                "vizConfig": {
                    "type": "polygon-category",
                    "labelField": null,
                    "labelColor": "#000000",
                    "labelFont": "Microsoft YaHei Regular",
                    "labelSize": 12,
                    "labelDx": 0,
                    "labelDy": 0,
                    "labelHaloColor": "#fff",
                    "labelAllowOverlap": false,
                    "labelPlacement": "point",
                    "blendingMode": "src-over",
                    "fieldName": "Score",
                    "fillOpacity": 0.8,
                    "outlineColor": "#ffffff",
                    "outlineOpacity": 0,
                    "outlineWidth": 1,
                    "fieldFiles": [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null
                    ],
                    "buckets": [{
                            "value": 10,
                            "color": "#67000d"
                        },
                        {
                            "value": 9,
                            "color": "#9f0e15"
                        },
                        {
                            "value": 8,
                            "color": "#cb2120"
                        },
                        {
                            "value": 7,
                            "color": "#e93e30"
                        },
                        {
                            "value": 6,
                            "color": "#fb6a4a"
                        },
                        {
                            "value": 5,
                            "color": "#fc9374"
                        },
                        {
                            "value": 4,
                            "color": "#fdbaa2"
                        },
                        {
                            "value": 3,
                            "color": "#fedcce"
                        },
                        {
                            "value": 2,
                            "color": "#fff5f0"
                        }
                    ]
                }
            }];

            $.ajax({
                url: 'https://geohey.com/s/dataviz/config?ak=' + ak,
                type: 'GET',
                dataType: 'jsonp',
                data: {
                    configJson: JSON.stringify(vizConfig),
                    layerGrouped: true,
                },
                success: function (data) {
                    if (data.code == 0) {
                        var vizId = data.data.vizId;
                        that.removeLayer('heatmap-source');
                        that.removeSource('heatmap');
                        that.heatmap.addSource('heatmap', {
                            'type': 'raster',
                            "tiles":['https://geohey.com/s/dataviz/' + vizId +'/{z}/{x}/{y}.png?ak=' + ak],
                            tileSize: 256
                        });
                        //添加图层
                        that.heatmap.addLayer({
                            'id': 'heatmap-source',
                            'type': 'raster',
                            'source': 'heatmap',
                        });
                        that.heatmap.addSource('polygon', {
                                "type": "geojson",
                                "data": {
                                    "type": "FeatureCollection",
                                    "features": [{
                                        "type": "Feature",
                                        "geometry": {
                                            "type": "Polygon",
                                            "coordinates": [JSON.parse(that.ops.geometry)]
                                        },
                                        "properties": {
                                            "id":1,
                                        },
                                    }]
                                }
                            });
                            that.heatmap.addLayer({
                                'id': 'polygons',
                                'type': 'fill',
                                'source': "polygon",
                                "paint": {
                                    'fill-color': "#feb500", // #088
                                    'fill-opacity': 0.2 
                                }
                            });
                    }
                },
                error: function () {
                    console.log('dataviz error')
                }
            });
            var boundingBox = this.getBoundingBox(this.$arrayStringToArray(this.ops.geometry)[0]);
            that.heatmap.fitBounds([[boundingBox.xMin-0.003, boundingBox.yMin-0.003], [boundingBox.xMax+0.003, boundingBox.yMax+0.003]]);

        },
        drawMapLayer(map) {
            var geojson={
                "type": "FeatureCollection",
                "features": []
            };
            switch (this.ops.type) {
                case "walking":
                case "riding":
                case "driving":
                    api.isochronous({
                        latitude: this.ops.latitude,
                        longitude: this.ops.longitude,
                        minutes: this.ops.minutes,
                        type: this.ops.type,
                        geohash: this.ops.geoHash
                    }).then(res => {
                        if(res.code == 200) {
                            this.ops.geometry = res.data;
                            map.addSource('polygon', {
                                "type": "geojson",
                                "data": {
                                    "type": "FeatureCollection",
                                    "features": [{
                                        "type": "Feature",
                                        "geometry": {
                                            "type": "Polygon",
                                            "coordinates": this.ops.geometry
                                        },
                                        "properties": {
                                            "id":1,
                                        },
                                    }]
                                }
                            });
                            map.addLayer({
                                'id': 'polygons',
                                'type': 'fill',
                                'source': "polygon",
                                "paint": {
                                    'fill-color': "#cb45fe", // #088
                                    'fill-opacity': 0.2 
                                }
                            });
                            map.addSource("centerP", {
                                'type': 'geojson',
                                data: {
                                    "type": "FeatureCollection",
                                    "features": [{
                                        "type": "Feature",
                                        "geometry": {
                                            "type": "Point",
                                            "coordinates": [this.ops.longitude, this.ops.latitude]
                                        }
                                    }
                                ]}
                            });
                            map.addLayer({
                                "id": "center",
                                "type": "circle",
                                "source": "centerP",
                                paint: {
                                    'circle-color': '#cb45fe', /* circle颜色 */
                                    'circle-radius': {
                                        'base': 4,
                                        'stops': [[12, 4], [22, 180]]
                                    }
                                }
                            });
                        }
                    })
                    break;
                case "ta":
                case "buffer":
                    map.addSource("polygon", {
                        "type": "geojson",
                        "data": {
                            "type": "FeatureCollection",
                            "features": [{
                                "type": "Feature",
                                "geometry": {
                                    "type": "Polygon",
                                    "coordinates": this.$arrayStringToArray(this.ops.geometry)
                                },
                                "properties": {
                                    "id":1,
                                },
                            }]
                        }
                    });
                    map.addLayer({
                        "id": "polygon",
                        "type": "fill",
                        "source": "polygon",
                        "layout": {},
                        "paint": {
                            "fill-color": "#feb500",
                            "fill-opacity": 0.3
                        },
                    });
                    if(this.ops.type == "buffer"){
                        map.addSource("centerP", {
                            'type': 'geojson',
                            data: {
                                "type": "FeatureCollection",
                                "features": [{
                                    "type": "Feature",
                                    "geometry": {
                                        "type": "Point",
                                        "coordinates": [this.ops.longitude, this.ops.latitude]
                                    }
                                }
                            ]}
                        });
                        map.addLayer({
                            "id": "center",
                            "type": "circle",
                            "source": "centerP",
                            paint: {
                                'circle-color': '#feb500', /* circle颜色 */
                                'circle-radius': {
                                    'base': 4,
                                    'stops': [[12, 4], [22, 180]]
                                }
                            }
                        });
                    }
                    var boundingBox = this.getBoundingBox(this.$arrayStringToArray(this.ops.geometry)[0]);
				    map.fitBounds([[boundingBox.xMin-0.003, boundingBox.yMin-0.003], [boundingBox.xMax+0.003, boundingBox.yMax+0.003]]);

                    break;
                case "pp":
                case "store":
                    var temp={
                        "type": "Feature",
                        "properties": {
                            "id":"dizhi",
                            "type":this.ops.type,
                            "icon":'dizhi'
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [this.ops.longitude, this.ops.latitude]
                        }
                    };
                    geojson.features.push(temp);
                    if (map.getLayer('pointlayer2')) {
                        map.getSource('geodataPoint2').setData(geojson);
                    }else{
                            map.addSource('geodataPoint2', { type: 'geojson', data: geojson });
                            map.addLayer({
                            'id': 'pointlayer2',
                            'type': 'symbol',
                            'source': "geodataPoint2",
                            "layout": {
                                'icon-image': ['get', 'icon'],
                                'icon-size': 1,
                                "icon-allow-overlap": true
                            }
                        });
                    }
                break;
            }
            
        },
        //画可是范围内商圈
        drawTALayer() {
            var boundingBox = this.getBoundingBox(this.$arrayStringToArray(this.ops.geometry)[0]);
            var options = {
                latitude: boundingBox.yMax+0.003,
                longitude: boundingBox.xMax+0.003,
                minimumLatitude: boundingBox.yMin-0.003,
                minimumLongitude: boundingBox.xMin-0.003,
                type: 4,
                cusNo: sessionStorage.cusNo,
                geometry: `[[${boundingBox.xMin-0.003}, ${boundingBox.yMin-0.003}],[${boundingBox.xMin-0.003},${boundingBox.yMax+0.003}],[${boundingBox.xMax+0.003},${boundingBox.yMin-0.003}],[${boundingBox.xMax+0.003},${boundingBox.yMax+0.003}]]`,
                city: sessionStorage.currentCity
            };
            api.renderMarker(options).then(res => {
                if(res.code == 200 &&res.data.length > 0){
                    if((this.ops.type == "ta" && res.data.length > 1) || this.ops.type !="ta") {
                        var feas = [];
                        res.data.map(m => {
                            feas.push({
                                type: "Feature",
                                properties: {
                                    "taid": m.id,
                                    "name": m.cnName,
                                    "geometry": m.geometry
                                },
                                geometry: {
                                    type: "Polygon",
                                    coordinates: this.$arrayStringToArray(m.geometry)
                                }
                            });
                        });
                        this.mmap.addSource('maines', {       /* 添加Source，类型是geojson */
                            type: 'geojson',
                            data: {                  /* geojson数据 */
                                type: "FeatureCollection",
                                features: feas
                            }
                        });
                        this.mmap.addLayer({
                            'id': 'maine',
                            'type': 'fill',            /* fill类型layer */
                            'source': 'maines',         
                            'layout': {},
                            'paint': {
                                'fill-color': '#0a38f1',  /* fill颜色 */
                                 'fill-opacity': 0.6,    /* fill透明度 */
                            }
                        });
                    }
                }
            });
        },
        // 住宅列表样式
        houseRowClassName({row, rowIndex}) {
            if (rowIndex % 2 !== 1) {
                return 'house-row';
            }
            return '';
        },
        // 办公列表样式
        officeRowClassName({row, rowIndex}) {
            if (rowIndex % 2 !== 1) {
                return 'office-row';
            }
            return '';
        },
        // 商场列表样式
        mallRowClassName({row, rowIndex}) {
            if (rowIndex % 2 !== 1) {
                return 'mall-row';
            }
            return '';
        },
        getBoundingBox(data) {
        	var bounds = {}, latitude, longitude;
		    for (var j = 0; j < data.length; j++) {
		      longitude = Number(data[j][0]);
		      latitude = Number(data[j][1]);
		      bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
		      bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
		      bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
		      bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
		  }
		  return bounds;
        },
        formatImageUrl(brandSurvey) {
            return constants.baseURL.API + brandSurvey.logo + `?${(brandSurvey.modelID || "12-34").replace(/-/g,"_")}`;
        },
        foo() { // 取消鼠标监听事件 菜单栏
            this.menuVisible = false
            document.removeEventListener('click', this.foo) // 要及时关掉监听，不关掉的是一个坑，不信你试试，虽然前台显示的时候没有啥毛病，加一个alert你就知道了
        },
        //下载图片
        dataURLToBlob(dataurl) {//ie 图片转格式
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type: mime})
        },
        downloadResult(name) {
            let canvasID = document.getElementById("imageWrapper");//this.$refs.imageWrapper//document.body
            let a = document.createElement('a');
            html2canvas(canvasID,{
                x: canvasID.offsetLeft,
                y: canvasID.offsetTop,
                width: window.screen.availWidth,//canvasID.clientWidth,
                height: canvasID.clientHeight,
                windowWidth: window.screen.availWidth,
                windowHeight: window.screen.availHeight,
                useCORS: true, // 允许图片跨域
                taintTest: true, // 在渲染前测试图片
                ignoreElements: (element) => { // 忽略不被绘制的dom元素
                    return element.className === 'downloadExcel'
                }
            }).then(canvas => {
                let dom = document.body.appendChild(canvas);
                dom.style.display = "none";
                a.style.display = "none";
                document.body.removeChild(dom);
                let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
                a.setAttribute("href", URL.createObjectURL(blob));
                a.setAttribute("download", name + ".png");
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(blob);
                document.body.removeChild(a);
                this.uploadReport(blob);
                this.loadingMap = false;
            });
        },
        //打印
        printOut(name) {
            this.loadingMap = true;
            // 先将滚动条置顶
            $(window).scrollTop(0); // jQuery 的方法
            document.body.scrollTop = 0; // IE的
            document.documentElement.scrollTop = 0; // 其他
            this.downloadResult(name)
        }, 
        uploadReport(blob) {
            let fileFormdata = new FormData();
            let reportIDFormdata = new FormData();
            fileFormdata.append(
                'blob', // key
                blob, //value,
            );
            fileFormdata.append("reportID", this.reportID);
            fileFormdata.append("mangerReportId", JSON.parse(sessionStorage.res).mangerReportId);
            $.ajax({
                url: constants.baseURL.API + "map/uploadReportImage",
                beforeSend: function (XMLHttpRequest) {
                    XMLHttpRequest.setRequestHeader("time", new Date().getTime());
                    XMLHttpRequest.setRequestHeader("token", sessionStorage.token || "");
                    XMLHttpRequest.setRequestHeader("timestamp", sessionStorage.timestamp || "");
                },
                type: 'POST',
                data: fileFormdata, // 上传formdata封装的数据
                dataType: 'JSON',
                cache: false, // 不缓存
                processData: false, // jQuery不要去处理发送的数据
                contentType: false, // jQuery不要去设置Content-Type请求头
                success: function (data) { //成功回调
                    
                }
            });
        },
        // 导出表格
        exportExcel(type, dataList, fileName) {
            this.loadingMap = true;
            require.ensure([], () => {
                const { export_json_to_excel } = require("../excel/Export2Excel");
                let tHeader = []; // 上面设置Excel的表格第一行的标题
                let filterVal = []; // 上面的index、nickName、name是tableData里对象的属性
                let list = dataList; // 把data里的tableData存到list
                switch(type) {
                    case 1:
                        tHeader = ["序号", "名称", "地址", "距商圈中心(m)", "房价", "物业费", "建筑面积", "规划户数", "容积率", "绿化率"];
                        filterVal = ["index", "name", "addr", "distince", "price", "re_fee", "tot_area", "tot_hh", "plot_rate","green_rate"];
                        break;
                    case 2:
                        tHeader = ["序号", "名称", "地址", "距商圈中心(m)","级别", "总建筑面积", "总楼层", "租金(元/月/平米)"];
                        filterVal = ["index", "name", "addr", "distince","level", "tot_area", "floor_num", "price"];
                        break;
                    case 3:
                        tHeader = ["序号", "名称", "地址", "距商圈中心(m)","人均消费", "开业时间", "开业状态", "点址面积(万平米)", "总体评分", "环境评分","设施评分"];
                        filterVal = ["index", "name", "addr", "distince","price", "open_date", "status", "tot_area", "overall","environment", "facility"];
                        break;
                    case 4:
                        tHeader = ["序号", "品牌", "门店名称", "距商圈中心(m)","电话", "地址"];
                        filterVal = ["index", "brand", "name", "distince","phone", "address"];
                        break;
                }
                const data = this.formatJson(filterVal, list);
                export_json_to_excel(tHeader, data, fileName+this.$Format("yyyyMMddhhmmss"));   //标题，数据，文件名
                this.loadingMap = false;
            });
        },

        formatJson(filterVal, jsonData) {
            return jsonData.map((v, i) => filterVal.map(j => { 
                let res = "";
                if(j == "index") {
                    res = i + 1;
                } else {
                    res = v[j];
                }
                return res;
            }));
        },
        //tableRowClassName
        tableRowClassName({ row, rowIndex }) {
            console.log(row);
        }
    }
}
</script>
<style>
.report-container {
    margin: 0 auto;
}
.report-home{
    position: relative;
}
.report-header{
    position: absolute;
    top: 30%;
    color: white;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    width: 100%;
}
.report-header>p:first-child {
    font-size: 50px;
}
.report-footer{
    position: absolute;
    bottom: 13%;
    color: white;
    text-align: center;
    width: 100%;
    font-size: 17px;
}
.report-part {
    margin-top: 20px;
    width: 97%;
    margin-left: 1%;
    page-break-after:always;
    padding: 10px;
    background: white;
    box-shadow: 1px 2px 4px 3px #00000036;
}
.report-title {
    display: inline-block;
    width: 200px;
    background: #1a71ff;
    border-bottom-right-radius: 5px;
    border-bottom: 5px transparent solid;
    border-image:linear-gradient(to right,#d71afd,#7d1aff) 1 10;
    color: #fff;
    text-align: center;
    padding: 16px 0;
    height: 24px;
    margin-top: 21px;
}
.report-progress {
    position: relative;
    right: -68%;
    margin-top: 26px;
}
.report-ta-title {
    font-size: 12px;
    border-left: 2px solid #1889ff;
    margin-left: -20px;
    padding-left: 20px;
}
.report-ta-title2 {
    font-size: 12px;
    margin-left: -20px;
    padding-left: 20px;
}
.report-ta-title3 {
    font-size: 16px;
    border-left: 2px solid #1889ff;
    margin-left: -20px;
    padding-left: 20px;
}
.report-ta-title3 span {
    line-height: 30px;
}
.report-ta-title3 img {
    width: 30px;
    vertical-align: middle;
    margin-right: 10px;
}

.flexdiv{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /*height: 50%;*/
    margin-top: 50px;
}
.flexdiv >div{
    padding:15px 0;
}
.two{
    width:50%;
    background: antiquewhite;
    font-size: 12px;
}
.three{
    width:33.3333%;
    background: #cddc39;
    font-size: 12px;
}
.rightBorder{
    border-right: 1px solid #ddd;
}
.distric-card {
    display: inline-block;
    width: 90%;
    height: 50px;
    font-size: 8px;
    text-align: center;
    border-radius: 5px;
    margin-top: 20px;
    color: #fff;
}
.distric-card2 {
    display: inline-block;
    width: 100%;
    height: 50px;
    font-size: 8px;
    border-radius: 5px;
    margin-top: 20px;
    color: #fff;
}
.c1 {
    background: #fe55a6;
}
.c2 {
    background: #8165ff;
}
.c3 {
    background: #ffaf38;
}
.distric-cardnum {
    font-size: 20px;
    position: relative;
    top: 5px;
}
.distric-carddes {
    position: relative;
    top: 6px;
}
.el-table .house-row {
    background: #fe55a61a;
}
.el-table .office-row {
    background: #8165ff1a;
}
.el-table .mall-row {
    background: #ffaf381a;
}
.pop-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #1889ff;
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
}
.pop-icon i {
    color: #ffffff;
}
.pop-content {
    height: 50px;
    line-height: 25px;
    color: #1889ff;
}
.pop-content div:first-child {
    font-size: 17px;
    font-weight: 600;
}
.pop-content div {
    font-size: 12px;
}
.weekday {
    background: #3591ff;
    height: 80px;
    border-radius: 10px;
    color: #ffffff;
}
.weekday>div:first-child {
    height: 80px;
    width: 2px;
    margin-left: 25px;
    background: #fff;
    display: inline-block;
}
.weekday>div:nth-child(2) {
    display: inline-block;
    margin-left: 10px;
    height: 80px;
    position: relative;
    top: -24px;
}
.weekday>div:nth-child(2)>div:first-child {
    font-size: 18px;
    font-weight: 600;
}
.weekday>div:nth-child(2)>div:nth-child(2) {
    font-size: 11px;
}
.weekend {
    background: #8568ff;
    height: 80px;
    border-radius: 10px;
    color: #ffffff;
}
.weekend>div:first-child {
    height: 80px;
    width: 2px;
    margin-left: 25px;
    background: #fff;
    display: inline-block;
}
.weekend>div:nth-child(2) {
    display: inline-block;
    margin-left: 10px;
    height: 80px;
    position: relative;
    top: -24px;
}
.weekend>div:nth-child(2)>div:first-child {
    font-size: 18px;
    font-weight: 600;
}
.weekend>div:nth-child(2)>div:nth-child(2) {
    font-size: 11px;
}
.menu_item {
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
}

.menu {
    height: 100px;
    width: 80px;
    position: absolute;
    border-radius: 10px;
    border: 1px solid #999999;
    background-color: #f4f4f4;
}

.menu li:hover {
    background-color: #1790ff;
    color: white;
}
.menu li{font-size:15px}
.reProgress .el-progress__text {
    font-size: 13px !important;
}
.print-table {
    width: 100%;
}
@media print {
    /* @page:right{
        @bottom-left {
            margin: 10pt 0 30pt 0;
            border-top: .25pt solid #666;
            content: "Our Cats";
            font-size: 9pt;
            color: #333;
        }
        @bottom-right {
            margin: 10pt 0 30pt 0;
            border-top: .25pt solid #666;
            content: counter(page);
            font-size: 9pt;
        }
        @top-right {
            content:  string(doctitle);
            margin: 30pt 0 10pt 0;
            font-size: 9pt;
            color: #333;
        }
    } */
    tr {
        page-break-inside: avoid;
    }
    html,body {
        height:210mm;
        width:397mm;
    }
    .page{
        font-size:10pt;
        width:initial;
    }
    .page .part2 td{
        height:1cm;
    }
    /* 首页图片 */
    .report-home {
        margin-top: 20mm;
        width: 355mm !important;
    }
    .print-img-ignore {
        visibility: hidden;
    }
    .report-header {
        top: 3mm !important;
        color: black;
    }
    .report-footer{
        color: black;
    }
    .report-home>.el-image {
        height: 50mm !important;
        width: 397mm !important;
    }
    /* 地图 */ 
    .print-map {
        height: 160mm !important;
        width: 350mm !important;
    }
    .print-map>#mmap {
        height: 150mm !important;
        width: 350mm !important;
        /* margin: 100px 0; */
    }
    /* 分页 */
    .report-part {
        page-break-after:always;
        margin-top: 30mm;
        margin-bottom: 30mm;
        width: 355mm;
    }
    .print-part {
        page-break-after:always;
        margin-top: 30mm;
        margin-bottom: 30mm;
        width:330mm !important;
    }
    /* 综合分析 */
    .synthesisAnalysis {
        height: 210mm;
    }
    .synthesisAnalysis .print-card {
        height: 175mm !important;
    }
    .synthesisAnalysis .synthesisAnalysis-content {
        margin-top: 10mm;
    }
    .break-always {
        page-break-after:always;
    }
    .print-card2 {
        height: 600px !important;
        margin-top: 50px !important;
    }
    .print-card2 .print2-content {
        margin-top: 50px !important;
    }
    .print-card3 {
        height: 110mm !important;
        /* margin-top: 50px !important; */
    }
    #map {
        height: 600px !important;
        width: 300px;
    }
    .printGathering {
        padding-top: 100px;
    }
    table {
        width: 100% !important;
    }
    .print-float-right {
        margin-left: 34% !important;
        position: absolute !important;
    }
    .downloadExcel {
        display: none;
    }
    .print-noborder {
        margin-top: 15px !important;
        width: 97%;
        margin-left: 0 !important;
        padding: 0 !important;
        background: white;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .report-part {
        margin-left: -6% !important;
    }
    .print-progress {
        display:inline-block;
        width: 80%;
        margin-left:20px;
    }
    .c1,.c2,.c3 {
        background-color: #fff !important;
        color: black !important;
    }
    .distric-card2 {
        margin-top: 0px !important;
    }
    .el-table {
        color: black !important;
    }
    body .el-table th.gutter{
        display: table-cell !important;
    }
}

</style>