<template>
    <div class="cc">
        <div class="cc" v-for="(o, i) in obj" :key="i">
                <div class="circle"  :style="{borderColor: `${o.color}`}"></div>
                <div class="text">{{o.name}}</div>
                <div class="number">{{o.number}}</div>
                <el-progress :percentage="100" :show-text="false" class="progress" :color="o.color" :style="{width: `${o.number*100/max}%`}"></el-progress>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            obj: {},
            max: 1
        };
    },
    props: ["ObjList"],
    // props: {
    //     maxNum:{
    //         type: Number,
    //         default: 0
    //     },
    //     Obj:{
    //         default: []
    //     }
    // },
    mounted(){
       // this.obj = this.Obj;
       // this.formatData();
    },
    methods:{
        formatData(){
            // this.Obj.color = this.$Color16();
            // this.obj = this.Obj;
            this.obj = this.ObjList.map(m =>  {
                m.color = m.color || this.$Color16();
                if(m.number > this.max) {
                    this.max = m.number;
                }
                return m;
            });
        }
    },
    watch: {
        ObjList(val){
            if(val) {
                this.formatData();
            }
        }
    }
}
</script>
<style>
.cc {
    font-size: 12px;
    margin-top: 15px;
}
.circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #0188e2;
    margin-right: 8px;
}
.text {
    display: inline-block;
    position: relative;
    top: -4px;
}
.number{
    float: right;
}
.progress {
    margin-top: 10px;
}
</style>